import React from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import videoTwo from "../../assets/videoTwo.png";

const GolfTecVideo = () => {
    return (
        <div>
            <VideoPlayer
                poster={videoTwo}
                videoSrc="https://gff-video-assets.s3.amazonaws.com/AdvancedWarmup.1484952808236161036d.mp4"
                vidId={4}
            />
        </div>
    );
};

export default GolfTecVideo;
