import React from "react";
import { useNavigate } from "react-router-dom"; // Note the change here
import Button from "react-bootstrap/Button";
import kingdomLogo from "../../assets/the-kingdom.png";
import "./the-kingdom-main.css";

const TheKingdomMain = () => {
    const navigate = useNavigate(); // Note the change here

    const goToVideo = () => {
        navigate("/the-kingdom/video"); // Note the change here
    };

    return (
        <div className="main">
            <img src={kingdomLogo} alt="The Kingdom Logo" className="logo" />
            <div className="overlay"></div>
            <video src="https://gff-video-assets.s3.amazonaws.com/scottie-start-video.mp4"
                   autoPlay
                   loop
                   muted
                   className="bg-video"
            />
            <div className="content">
                <h1>your lesson</h1>
                <h1>starts here</h1>
                <Button variant="success" onClick={goToVideo} className="cta-btn">tap to start</Button>
            </div>
        </div>
    );
};

export default TheKingdomMain;