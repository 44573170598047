import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Button from "react-bootstrap/Button";
import "./main.css";

const Main = ({onClick}) => {
    const navigate = useNavigate(); // Initialize useNavigate

    const goToVideoSelection = () => {
        navigate("/select-video"); // Navigate to the video selection page
    };

    return (
        <div className="main">
            <div className="overlay"></div>
            <video src="https://gff-video-assets.s3.amazonaws.com/demoVideoModeBackground.242da0112241293bd2a8.mp4"
                   autoPlay
                   loop
                   muted
                   className="bg-video"
            />
            <div className="content">
                <h1>warmup like</h1>
                <h1>a <span className="strikeout">golfer</span> <span id="pro-txt">pro</span></h1>
                <Button variant="success" onClick={goToVideoSelection} className="cta-btn">tap to start</Button>
            </div>
        </div>
    )
}

export default Main;