// import React, {useState} from "react";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Note the change here
import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import Main from './components/Main/Main';
import SelectVideo from "./components/SelectVideo/SelectVideo";
import TheKingdomMain from './components/TheKingdomMain/TheKingdomMain';
import TheKingdomVideo from './components/TheKingdomVideo/TheKingdomVideo';
import GolfTec from "./components/GolfTec/GolfTec";
import GolfTecVideo from "./components/GolfTecVideo/GolfTecVideo";

function App() {
  // const [active, setActive] = useState("main");
  //
  // const changeComponent = () => {
  //   setActive("selectVideo");
  // }

  return (
    <Router>
      <div className="App">
        {/*{active === "main" && <Main onClick={changeComponent} />}*/}
        {/*{active === "selectVideo" && <SelectVideo />}*/}
        <Routes> {/* Note the change here */}
          <Route path="/" element={<Main />} />
          <Route path="/select-video" element={<SelectVideo />} />
          <Route path="/the-kingdom" element={<TheKingdomMain />} />
          <Route path="/the-kingdom/video" element={<TheKingdomVideo />} />
          <Route path="/golftec" element={<GolfTec />} />
          <Route path="/golftec/video" element={<GolfTecVideo />} />
        </Routes> {/* Note the change here */}
      </div>
    </Router>
  );
}

export default App;
