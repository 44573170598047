import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import kingdomLogo from "../../assets/golftec-logo.png";
import "./golftec.css";

const GolfTec = () => {
    const navigate = useNavigate();

    const goToVideo = () => {
        navigate("/golftec/video");
    };

    return (
        <div className="main">
            <img src={kingdomLogo} alt="GolfTec Logo" className="logo" />
            <div className="overlay"></div>
            <video src="https://gff-video-assets.s3.amazonaws.com/scottie-start-video.mp4"
                   autoPlay
                   loop
                   muted
                   className="bg-video"
            />
            <div className="content">
                <h1>your lesson</h1>
                <h1>starts here</h1>
                <Button variant="success" onClick={goToVideo} className="cta-btn">tap to start</Button>
            </div>
        </div>
    );
};

export default GolfTec;