import React, {useRef} from "react";
import {DefaultPlayer as Video} from "react-html5video";
import "react-html5video/dist/styles.css";
import "./video-player.css";

const VideoPlayer = ({poster, videoSrc, vidId}) => {
    const videoRef = useRef(null);

    const handleFullScreen = () => {
        console.log('Video Started');
        console.log('Entering FullScreen');
        const el = document.getElementById(`full-screenVideo-${vidId}`);
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitEnterFullscreen) {
            el.webkitEnterFullscreen();
        }
    }

    const handleExitFullScreen = () => {
        console.log('Video has ended');
        console.log('Exiting fullscreen');
        const el = document.getElementById(`full-screenVideo-${vidId}`);
        el.webkitExitFullscreen();
        window.location.reload();
    }

    const handleFullscreenChange = () => {
        const el = document.getElementById(`full-screenVideo-${vidId}`);

        document.addEventListener("fullscreenchange", function () {
            console.log('Video Changed');

            if (document.fullscreenElement) {
                console.log('I am on fullscreen mode');
            } else {
                console.log('I am out of fullscreen mode');
                el.pause();
            }

        }, false);
        document.addEventListener("mozfullscreenchange", function () {
            console.log('Video Changed');

            if (document.fullscreenElement) {
                console.log('I am on fullscreen mode');
            } else {
                console.log('I am out of fullscreen mode');
                el.pause();
            }
        }, false);
        document.addEventListener("webkitfullscreenchange", function () {
            console.log('Video Changed');

            if (document.fullscreenElement) {
                console.log('I am on fullscreen mode');
            } else {
                console.log('I am out of fullscreen mode');
                el.pause();
            }
        }, false);
    };

    return (
        <Video id={`full-screenVideo-${vidId}`}
               className="video-container"
               poster={poster}
               ref={videoRef}
               onPlay={(e) => {
                   handleFullScreen();
                   handleFullscreenChange();
               }}
               onEnded={(e) => {
                   handleExitFullScreen();
                }
               }
        >
            <source src={videoSrc} type="video/mp4" />
        </Video>
    )
}

export default VideoPlayer;