import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import videoOne from "../../assets/videoOne.png";
import videoTwo from "../../assets/videoTwo.png";
import videoThree from "../../assets/videoThree.png";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import logoGF from "../../assets/gf-logo-new.png";
import "./select-video.css";

const SelectVideo = () => {
    const handleClick = () => {
        window.location.reload();
    }

    return (
        <div className="select-video-body">
            <div className="select-content">
                <Container>
                    <Row>
                        <Col sm>
                            <img src={logoGF} alt="Golf Forever" id="gff-logo" onClick={() => handleClick()} />
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <div id="select-header-container">
                            <h2>Choose a short pre-round routine below to</h2>
                            <h2>warm up just like the pros.</h2>
                        </div>
                    </Row>
                </Container>

                <Container className="video-row">
                    <Row>
                        <Col sm>
                            <Card style={{ width: '18rem', background: 'none' }}>
                                <VideoPlayer poster={videoOne}
                                             videoSrc="https://gff-video-assets.s3.amazonaws.com/BasicWarmup.23b557da2f581f008831.mp4"
                                             vidId={1}
                                />
                                <Card.Body>
                                    <Card.Title>basic</Card.Title><br/>
                                    <Card.Text>
                                        7 Minute Warmup<br/>
                                        <b className="golfer-name">Pete Holman</b><br/><br/>
                                        2022 IDEAfit - Fitness Leader Of The Year
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm>
                            <Card style={{ width: '18rem', background: 'none' }}>
                                <VideoPlayer poster={videoThree}
                                             videoSrc="https://gff-video-assets.s3.amazonaws.com/IntermediateWarmup.7e0dc5f460eb0d734f82.mp4"
                                             vidId={2}
                                />
                                <Card.Body>
                                    <Card.Title>intermediate</Card.Title><br/>
                                    <Card.Text>
                                        7 Minute Warmup<br/>
                                        <b className="golfer-name">Justin Leonard</b><br/><br/>
                                        12x PGA Tour Winner And Major Champion
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm>
                            <Card style={{ width: '18rem', background: 'none' }}>
                                <VideoPlayer poster={videoTwo}
                                             videoSrc="https://gff-video-assets.s3.amazonaws.com/AdvancedWarmup.1484952808236161036d.mp4"
                                             vidId={3}
                                />
                                <Card.Body>
                                    <Card.Title>advanced</Card.Title><br/>
                                    <Card.Text>
                                        Tour-Level Workout<br/>
                                        <b className="golfer-name">Scottie Scheffler</b><br/><br/>
                                        2022 Masters Champion
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SelectVideo;